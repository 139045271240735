<template>
	<div class="app-container">
		<!-- filter -->
		<div class="filter-container">
			<div>
				<!-- 关键字搜索 -->
				<div class="filter-item">
					<label class="label">活动名称:</label>
					<el-input
						class="input-item"
						style="margin-left:5px;width:250px"
						v-model="searchData.activityName"
						@keyup.enter.native="search"
						placeholder="请输入活动名称"
					></el-input>
				</div>
				<!-- 时间筛选 -->
				<div class="filter-item">
					<label class="label">活动时间:</label>
					<el-date-picker
						v-model="applyTime"
						type="datetimerange"
						range-separator="～"
						start-placeholder="开始日期"
						end-placeholder="结束日期"
						align="right"
						value-format="yyyy-MM-dd HH:mm:ss"
					></el-date-picker>
				</div>
               <!-- <div class="filter-item">
					<label class="label">活动状态:</label>
					<el-select class="option-item" v-model="searchData.type">
						<el-option label="全部" :value="null"></el-option>
						<el-option
							v-for="item in refundStatus"
							:key="item.value"
							:label="item.label"
							:value="item.value"
						></el-option>
					</el-select>
				</div> -->
				<!-- button -->
				<div class="filter-item">
					<el-button type="primary" style="width:100px;margin-right:20px;" @click="search">查询</el-button>
					<button-permissions :datas="'addTheme'">
						<el-button type="primary" style="width:100px;margin-right:20px;" @click="handleDetailShow({id:0},0)">添加活动</el-button>
					</button-permissions>
					<!-- <button-permissions :datas="'addKillTime'">
						<el-button type="primary" style="width:100px;" @click="groupExport">添加时段</el-button>
					</button-permissions> -->
				</div>
			</div>
		</div>
		<!-- tab切换 -->
		<el-tabs v-model="searchData.activityType" @tab-click="search">
			<el-tab-pane
				v-for="item in listStateList"
				:key="item.id"
				:label="item.value"
				:name="item.id+''"
			></el-tab-pane>
		</el-tabs>
		<!-- table -->
		<div class="table-container">
			<el-table :data="tableData" v-loading="loading" border>
                <el-table-column prop="activityName" label="活动名称"></el-table-column>
				<el-table-column prop="startTime" label="开始时间" ></el-table-column>
				<el-table-column prop="endTime" label="结束时间"></el-table-column>
				<el-table-column prop="stateValue" label="活动状态"></el-table-column>
				<el-table-column label="操作" width="200">
					<template slot-scope="scope">
						<button-permissions :datas="'themeEdit'">
							<div class="table-button" @click="handleDetailShow(scope.row,1)">编辑</div>
						</button-permissions>
						<button-permissions :datas="'themeDetail'">
							<div class="table-button" @click="handleDetailShow(scope.row,2)">查看</div>
						</button-permissions>
						<button-permissions :datas="'themeFinish'" v-if="scope.row.status!=2">
							<div class="table-button" @click="handleFinish(scope.row)">结束活动</div>
						</button-permissions>
					</template>
				</el-table-column>
			</el-table>

			<el-pagination
				v-if="searchData.total"
				style="margin-top:20px;float:right;"
				@size-change="handleSizeChange"
				@current-change="handleCurrentChange"
				:current-page="searchData.pageNo"
				:page-sizes="[10, 20, 30, 40, 50]"
				:page-size="searchData.size"
				layout="total, sizes, prev, pager, next, jumper"
				:total="searchData.total"
			></el-pagination>
		</div>
	</div>
</template>

<script>
import {
	subjectActivityList,
	subjectfinishActIds
} from "@/api/goods";
import buttonPermissions from '@/components/buttonPermissions';
export default {
    name:'refundAfterOrder',
	components: {
		buttonPermissions
	},
	data () {
		return {
			applyTime: '',  //时间
			searchData: {
				activityName: '',//活动名称
				applyStartTime: '',    //申请开始时间
                applyEndTime: '',      //申请结束时间
				activityType: '-1', //活动类型
				total: 0,
				pageNo: 1,
				size: 20
			},
            loading: false,
			listStateList: [
				{
					id: '-1',
					value: '所有活动',
				},
				{
					id: 0,
					value: '未开始',
				},
				{
					id: 1,
					value: '进行中',
				},
				{
					id: 2,
					value: '已结束',
				},
			],
			refundStatus: [{
				value: 1,
				label: '未开始'
			}, {
				value: 2,
				label: '秒杀中'
			}, {
				value: 3,
				label: '已结束'
			}],
			tableData: [],
		}
	},
	async beforeMount () {
        // console.log(1232);
		this.getList();
	},
	computed: {

	},
	methods: {
		// 获取列表数据
		async getList () {
			this.loading = true
			if(this.applyTime){
				this.searchData.applyStartTime = this.applyTime[0];
				this.searchData.applyEndTime = this.applyTime[1];
			}else{
				this.searchData.applyStartTime = '';
				this.searchData.applyEndTime = '';
			}
			try {
				let data = {
					pageNo:this.searchData.pageNo,
					pageSize : this.searchData.size,
					activityName:this.searchData.activityName,
					startTime:this.searchData.applyStartTime,
					endTime:this.searchData.applyEndTime,
					status:+this.searchData.activityType
				};
				let result = '';
				result = await subjectActivityList(data);
				result.data.records.map(item=>{
					item.stateValue = item.status==0 ? '未开始': (item.status==2 ? '已结束': '进行中')
					return item;
				})
				this.tableData = result.data.records;
				this.searchData.total = result.data.total;
			} catch (error) {
				console.log(error);
				this.loading = false
			} finally {
				this.loading = false;
			}
		},
		//搜索
		search () {
			this.searchData.pageNo = 1
			this.searchData.isDownload = false;    //下载文件标记
			if (!this.searchData.total) {
				this.searchData.size = 20
			}
			this.getList()
		},
		//跳转至详情
		handleDetailShow (row,type) {
            let params = {
                id: row.id,
				type:type
            }
			this.publicToDetailsR(params,'/mallPage/mallPageThemeDetails',this);
		},
		async handleFinish(row){
			let data = {
				id:row.id
			};
			let res = await subjectfinishActIds(data);
			if(res.code==200){
				if(res.success){
					this.$message({
						showClose: true,
						type: 'success',
						message: "活动结束成功"
					});
					this.getList();
				}else{
					this.$message({
						showClose: true,
						type: 'error',
						message: res.alertMsg
					});
				}
			}else{
				this.$message({
					showClose: true,
					type: 'error',
					message: res.alertMsg
				});
			}
		},
		// 切换显示条数
		handleSizeChange (val) {
			this.searchData.size = val;
			this.getList();
		},
		// 翻页
		handleCurrentChange (val) {
			this.searchData.pageNo = val;
			this.getList();
		},
	}
}
</script>

<style lang="less" scoped>
.app-container {
	background-color: #fff;
}

.input-item {
	margin-left: 5px;
}

.table-button {
	color: #409eff;
	display: inline-block;
	margin-right: 20px;
	cursor: pointer;
}

/deep/.el-table__fixed-body-wrapper {
	background: #ffffff;
}
/deep/.el-table__fixed-header-wrapper {
	background: #f8f8f9;
}
</style>
